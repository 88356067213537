import type { StackProps } from '@chakra-ui/react'
import { Box, VStack } from '@chakra-ui/react'
import type { ArticleBoxesWidgetItemType } from 'ecosystem'
import { GrandHeading, GrandImage, GrandLink, GrandNextLink, HTMLArticle } from 'ui'
import { hrefFromAppPaths } from 'shared-utils'
import { useAppPaths } from 'ui/hooks'
import { WIDGET_GROUP_CN } from '../../constants'
import { articleBoxesCn as cn } from './constants'

type ArticleBoxes1WidgetItemProps = ArticleBoxesWidgetItemType & StackProps

const ArticleBoxes1WidgetItem = ({
  title,
  sub,
  pathObj,
  imgUrl,
  label,
  isExternal,
  ...props
}: ArticleBoxes1WidgetItemProps) => {
  const isLink = Boolean(pathObj && !label)
  const appPaths = useAppPaths()

  return (
    <VStack
      as={isLink ? GrandNextLink : 'div'}
      className={cn(WIDGET_GROUP_CN.ITEM_WRAPPER)}
      {...(isLink
        ? {
            href: pathObj ? hrefFromAppPaths(pathObj, appPaths) : '/',
            'aria-label': title,
            isExternal
          }
        : {})}
      alignItems="center"
      spacing={4}
      {...props}>
      {imgUrl ? (
        <Box
          h={{
            base: '35px',
            md: '45px'
          }}
          mb="3"
          mt="2"
          position="relative"
          w={{
            base: '35px',
            md: '45px'
          }}>
          <GrandImage
            alt={title || ''}
            fill
            objectFit="contain"
            quality={100}
            sizes="50px"
            src={imgUrl}
          />
        </Box>
      ) : null}

      {title ? (
        <GrandHeading
          as="h4"
          className={cn(WIDGET_GROUP_CN.ITEM_TITLE)}
          containerProps={{
            minH: {
              base: '38px',
              md: '48px'
            },
            justifyContent: 'center',
            m: 0
          }}
          fontSize={{
            base: 'sm',
            md: 'xl'
          }}
          fontWeight="bold"
          maxWidth="80%"
          textAlign="center"
          title={title}
        />
      ) : null}

      {sub ? (
        <HTMLArticle
          className={cn(WIDGET_GROUP_CN.ITEM_TEXT_WRAPPER)}
          content={sub}
          fontSize="sm"
          textAlign="center"
          w="full"
        />
      ) : null}

      {label && pathObj ? (
        <GrandLink
          _hover={{
            textDecoration: 'none'
          }}
          aria-label={title || ''}
          className={cn(WIDGET_GROUP_CN.ITEM_LINK_LABEL)}
          href={hrefFromAppPaths(pathObj, appPaths)}
          isExternal={isExternal}
          textDecoration="underline">
          {label}
        </GrandLink>
      ) : null}
    </VStack>
  )
}

export default ArticleBoxes1WidgetItem
